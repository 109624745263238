import React, { useState, useRef, useEffect, useCallback } from 'react';
import { cn } from '../../../../data/configs/utils';

const OriTextArea = ({
  maxRows = 10,
  value = "",
  onChange = () => { },
  className = "",
  autofocus = false,
  ...props
}) => {
  const [rows, setRows] = useState(1);
  const textareaRef = useRef(null);
  const pseudoTextareaRef = useRef(null);

  const updateRows = useCallback(() => {
    if (textareaRef.current) {
      const textarea = textareaRef.current;
      const pseudoTextarea = pseudoTextareaRef.current;
      const lineHeight = parseInt(getComputedStyle(textarea).lineHeight, 10);

      textarea.style.height = 'auto';
      pseudoTextarea.style.height = 'auto';
      pseudoTextarea.value = textarea.value || props.placeholder || '';
      const scrollHeight = pseudoTextarea.scrollHeight;
      const newRows = Math.min(Math.floor(scrollHeight / lineHeight), maxRows);

      setRows(newRows);
    }
  }, [maxRows, props.placeholder]);

  useEffect(() => {
    updateRows();
    window.addEventListener('resize', updateRows);
    return () => window.removeEventListener('resize', updateRows);
  }, [updateRows]);

  useEffect(() => {
    if (autofocus) {
      textareaRef.current.focus();
    }
  },[autofocus])

  const handleInput = () => {
    updateRows();
  };

  return (
    <>
      <textarea
        ref={textareaRef}
        onInput={handleInput}
        rows={rows}
        onChange={onChange}
        value={value}
        className={cn(``, className)}
        {...props}
      />
      <textarea
        ref={pseudoTextareaRef}
        rows={1}
        onChange={onChange}
        onInput={handleInput}
        value={value}
        className={cn(`fixed invisible`, className)}
        {...props}
      />
    </>
  );
};

export default OriTextArea;