import React from 'react'

const ResourcesPage = () => {
  return (
    <div className='h-full w-full flex flex-col gap-4 items-center justify-center'>
      <p className='text-6xl text-primaryContrast'>
        <i class="ri-hammer-line"></i>
      </p>
      <p className='text-2xl font-bold text-primaryContrast'>Resources</p>
      <p className='text-sm text-primaryContrast'><i class="ri-time-line"></i> Coming soon</p>
    </div>
  )
}

export default ResourcesPage