import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { getChatHistory } from '../../../../../data/store/botSlice';
import * as _ from 'lodash'
import dayjs from 'dayjs'
import useChatbotActions from '../../../../../data/hooks/useChatBotActions';
import ChatHistoryTile from '../../../../components/ChatHistoryTile';

const ChatWelcomePage = () => {
    const dispatch = useDispatch()
    const avatarUrl = useSelector((state) => state.themeDetails.avatarUrl)
    const botName = useSelector((state) => state.themeDetails.botName)
    const homeHeading = useSelector((state) => state.themeDetails.homeHeading)
    const tenantId = useSelector((state) => state.botDetails.tenantId)
    const psid = useSelector((state) => state.botDetails.psid)
    const lastChatData = useSelector((state) => _.get(state, 'botDetails.recentChat', null))
    const { handleStartNewConversation, isChatActive, handleChatClicked } = useChatbotActions()

    const isLastChatActive = useMemo(() => {
        return isChatActive(lastChatData)
    }, [lastChatData, isChatActive])

    useEffect(() => {
        // Fetching the last chat message to show on the welcome page
        dispatch(getChatHistory({
            tenantId,
            psid,
            page: 1,
            limit: 1,
            timezone: "Asia/Calcutta",
            "filters": {
                "startDate": dayjs().subtract(3, 'months').toISOString(),
                "endDate": dayjs().toISOString()
            },
        }))
    }, [dispatch, tenantId, psid])

    return (
        <div className='flex flex-col justify-center gap-6 items-center p-4 h-full'>
            <div className='h-36 aspect-square rounded-full FadeIn'>
                <img
                    src={avatarUrl}
                    alt={botName}
                    className="h-full aspect-square rounded-full object-cover" />
            </div>
            <p className='text-2xl text-primaryContrast font-black text-center FadeIn'>
                {homeHeading ? homeHeading : botName}
            </p>
            <div className='w-full flex flex-col gap-2 FadeIn'>

                <div
                    onClick={isLastChatActive ? () => handleChatClicked(lastChatData) : handleStartNewConversation}
                    className='p-3 px-5 cursor-pointer flex justify-between bg-primary text-lg text-primaryContrast rounded-3xl w-full hover:bg-secondary hover:text-secondaryContrast transition-all duration-500'>
                    <p className='font-bold'>
                        {isLastChatActive ? (
                            'Continue Conversation'
                        ) : (
                            'Start a Conversation'
                        )}
                    </p>
                    <p>
                        <i class="ri-send-plane-2-line"></i>
                    </p>
                </div>
                {lastChatData && (
                    <ChatHistoryTile chat={lastChatData} />
                )}
            </div>
        </div>
    )
}

export default ChatWelcomePage