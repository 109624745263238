import PropTypes from "prop-types"
import { lazy, Suspense, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux"
import { cn } from "../../../data/configs/utils.js";
import { MemoryRouter, Routes, Route } from "react-router-dom";
import ChatWelcomePage from "./ChatHome/ChatWelcomePage/index.js"
import { ROUTE_PATHS } from "../../../data/configs/constants.js";
import ChatHistoryPage from "./ChatHome/ChatHistoryPage/index.js"
import ChatWindow from "./ChatWindow/index.js"
import ChatHome from "./ChatHome/index.js";
import { makeSocketConnection } from "../../../data/store/botSlice/index.js";
import ResourcesPage from "./ChatHome/ResourcesPage/index.js";
import OriLoader from "../../components/Custom/OriLoader/index.js";

const KnowledgeSource = lazy(() => import("../../components/KnowledgeSource"))

const Chatbot = ({ className }) => {
  const dispatch = useDispatch()
  const mobileView = useSelector((state) => state.themeDetails.mobileView)
  const fullScreenBot = useSelector((state) => state.themeDetails.fullScreenBot)
  const botPosition = useSelector((state) => state.themeDetails.botPosition)
  const isBotOpen = useSelector((state) => state.botDetails.isBotOpen)
  const watermark = useSelector((state) => state.themeDetails.watermark)
  const branding = useSelector((state) => state.themeDetails.branding)
  const isSocketConnected = useSelector(
    (state) => state.botDetails.isSocketConnected
  )

  useEffect(() => {
    if (!isSocketConnected) dispatch(makeSocketConnection())
  }, [dispatch, isSocketConnected])


  return <div className={cn(
    `overflow-hidden fixed Animated bg-background`,
    botPosition === 'left' ? 'origin-bottom-left' : 'origin-bottom-right',
    mobileView || fullScreenBot ? 'top-0 left-0 right-0 bottom-0' : 'top-[8px] left-[8px] right-[8px] rounded-3xl bottom-[--trigger-size] mb-4 shadow-xl border',
    className
  )}>
    <div className={cn(
      "h-full",
      watermark && (branding?.active || branding?.brandName) && 'pb-8'
      )}>
      <MemoryRouter initialEntries={["/"]}>
        <Routes>
          <Route path={ROUTE_PATHS.HOME} element={<ChatHome />}>
            <Route path={ROUTE_PATHS.HOME} element={<ChatWelcomePage />} />
            <Route path={ROUTE_PATHS.CHAT_HISTORY} element={<ChatHistoryPage />} />
            <Route path={ROUTE_PATHS.RESOURCES} element={<ResourcesPage />} />
          </Route>
          <Route path={ROUTE_PATHS.CHAT} element={<ChatWindow />} />
          <Route path={ROUTE_PATHS.CHAT_WITH_SESSIONID} element={<ChatWindow />} />
          <Route path={ROUTE_PATHS.KNOWLEDGE_SOURCE} element={
            <Suspense fallback={<OriLoader />}>
              <KnowledgeSource />
            </Suspense>
          }></Route>
        </Routes>
      </MemoryRouter>
    </div>
    <div className="absolute bottom-0 left-0 right-0">
      {watermark &&
        (branding?.active || branding?.brandName) && (
          <p className="text-xs font-light text-center p-2 pt-0">Powered by {" "}
            <span className="font-bold">
              {branding.url ? (
                <a
                  href={branding.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  {branding.brandName}
                </a>
              ) : (
                branding.brandName
              )}
              {branding.showVersion &&
                process.env.REACT_APP_VERSION &&
                `  ${process.env.REACT_APP_VERSION}`}
            </span>
          </p>
        )
      }
    </div>
  </div>

  // TODO: Remove commented code after review
  // return (
  //   <Paper
  //     className={className}
  //     elevation={mobileView || fullScreenBot ? 0 : 3}
  //     sx={{
  //       display: "flex",
  //       flexDirection: "column",
  //       overflow: "hidden",
  //       transformOrigin: fullScreenBot ? "" : `${botPosition} bottom`,
  //       position: "fixed",
  //       left: mobileView || fullScreenBot ? 0 : "8px",
  //       right: mobileView || fullScreenBot ? 0 : "8px",
  //       top: mobileView || fullScreenBot ? 0 : "8px",
  //       bottom:
  //         mobileView || fullScreenBot
  //           ? 0
  //           : `calc(${DEFAULT_THEME_VARIABLES.TRIGGER_SIZE} + ${DEFAULT_THEME_VARIABLES.TRIGGER_BOT_OFFSET})`,

  //       borderRadius: `${mobileView || fullScreenBot ? 0 : "16px"}`,
  //     }}
  //   >
  //     {showKnowledgeSource ? (
  //       <Suspense fallback={<SpinnerLoader />}>
  //         <KnowledgeSource />
  //       </Suspense>
  //     ) : (
  //       <>
  //         <Header />
  //         <ChatBody />
  //         <Footer />
  //       </>
  //     )}
  //   </Paper>
  // )s
}

Chatbot.propTypes = {
  className: PropTypes.string,
}

Chatbot.defaultProps = {
  className: "",
}

export default Chatbot
