import { useSelector } from "react-redux"
import useChatbotActions from "../../../data/hooks/useChatBotActions"
import dayjs from "dayjs"
import { cn } from "../../../data/configs/utils"
var relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

const ChatHistoryTile = ({ chat, isLatest = true }) => {
    const {handleChatClicked, isChatActive} = useChatbotActions()
    const avatarUrl = useSelector((state) => state.themeDetails.avatarUrl)

    return (
        <div className={cn('FadeIn bg-background p-3 rounded-3xl flex justify-between items-center gap-4 shadow-xl cursor-pointer',
            'hover:bg-secondary hover:text-secondaryContrast transition-all duration-500'
         )}
        onClick={()=>{
            handleChatClicked(chat, isLatest)
        }}>
            <div className='flex-shrink-0 relative'>
                <img src={avatarUrl} alt="bot avatar" className='h-12 w-12 rounded-full' />
                {isChatActive(chat, isLatest) && (
                    <p className='absolute right-0 bottom-0 text-xs text-success shadow-xl'>
                        <i class="ri-circle-fill"></i>
                    </p>
                )}
            </div>
            <div className='flex-grow'>
                <div className='flex justify-between items-center gap-2'>
                    <p className='font-bold line-clamp-1'>{chat?.name}</p>
                    <p className='text-[10px] leading-5 flex-shrink-0'>{dayjs(chat.lastBotMessageTimeStamp).fromNow()}</p>
                </div>
                <p className='text-xs line-clamp-2'>{(typeof chat?.lastBotMessage) === 'string' ? chat?.lastBotMessage : ''}</p>
            </div>
            <p className='font-bold text-xl'>
                <i class="ri-arrow-right-s-line"></i>
            </p>
        </div>
    )
}

export default ChatHistoryTile