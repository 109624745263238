import { lazy, useCallback, useEffect, useRef, Suspense } from "react"
import { useSelector, useDispatch } from "react-redux"
import PropTypes from "prop-types"
import dayjs from "dayjs"

import {
  markOrUnmarkFlagOnMessage,
  sendMessage,
  addMessages,
  updateMessage,
} from "../../../data/store/botSlice"
import { BUBBLE_SHAPE, LOCAL_STORAGE, MESSAGE_SENDER, MESSAGE_TYPES } from "../../../data/configs/constants"
import {
// linkify,
// createPayloadForApi,
playNotification, sendUpdateToParent, uniqueId, getUrlParams, cn,
copyToClipboard,
getDataFromLocalStorage
} from "../../../data/configs/utils";

import ErrorBoundary from "../ErrorBoundary"
import MessageWrapper from "../MessageWrapper"
import GlobalSnackBar from "../GlobalSnackBar"
import JumpingDots from "../JumpingDots"
import UploadedDocumentMessage from "../UploadedDocumentMessage"
import MessageCorouselData from "./MessageCorouselData.js"
import Markdown from "react-markdown"
import * as _ from "lodash"
import OriSkeleton from "../Custom/OriSkeleton/index.js"
import OriIconButton from "../Custom/OriIconButton/index.js"
import OriButton from "../Custom/OriButton/index.js"

const DynamicForm = lazy(() => import("../DynamicForm"))
const PARAMS = getUrlParams()

const Body = ({ messages, notification, handleBrainClicked }) => {
  const dispatch = useDispatch()
  const psid = useSelector((state) => state.botDetails.psid)
  const tenantId = useSelector((state) => state.botDetails.tenantId)
  const typingInfo = useSelector((state) => state.botDetails.typingInfo)
  const loading = useSelector((state) => state.themeDetails.loading)
  const isTestBot = useSelector((state) => state.botDetails.isTestBot)
  const bubbleShape = useSelector((state) => state.themeDetails.bubbleShape)
  const startNewSession = useSelector(
    (state) => state.botDetails.startNewSession
  )
  const firstUnreadMessageId = useSelector(
    (state) => state.botDetails.firstUnreadMessageId
  )
  const bodyRef = useRef()
  const didMount = useRef(false)
  const scrollTimer = useRef(null)
  const urlParams = new URLSearchParams(window.location.search)
  const isTraining = urlParams.get("training") === "true"

  useEffect(() => {
    bodyRef.current.scrollTop = bodyRef.current.scrollHeight
    if (firstUnreadMessageId) {
      var firstUnreadMessage = document.getElementById(firstUnreadMessageId)
      firstUnreadMessage?.scrollIntoView()
    }
  }, [firstUnreadMessageId])

  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true
    } else {
      bodyRef.current.scroll({
        top: bodyRef.current.scrollHeight,
        behavior: "smooth",
      })
    }
  }, [messages.length])

  useEffect(() => {
    if (document.hidden || notification) playNotification()
  }, [messages.length, notification])


  const handleRetrySend = (message) => {
    dispatch(
      sendMessage({
        type: "message",
        info: {
          sender: MESSAGE_SENDER.USER,
          sessionId: getDataFromLocalStorage(LOCAL_STORAGE.SESSION_ID, uniqueId()), 
        },
        metadata: {},
        message,
      })
    )
  }

  const handleFlagClick = (message) => {
    const payload = {
      tenantId,
      psid,
      messageId: message.id,
      chatLogId: message.chatLogId,
      isTrainingRequired: !message.flag,
    }
    dispatch(markOrUnmarkFlagOnMessage(payload))
      .unwrap()
      .then((res) => {
        if (res?.data && res?.data?.isTrainingRequired)
          sendUpdateToParent("flag", res.data, PARAMS.parentOrigin)
      })
  }

  const handleButtonClick = (button) => {
    if (button?.props?.href) return
    if (button.text) {
      const message = {
        id: uniqueId(),
        type: MESSAGE_TYPES.TEXT,
        sender: MESSAGE_SENDER.USER,
        payload: {
          text: button.text,
        },
        status: "pending",
        timestamp: new Date().getTime(),
      }
      dispatch(addMessages({ messages: [message] }))
      dispatch(
        sendMessage({
          type: "message",
          info: {
            sender: MESSAGE_SENDER.USER,
            sessionId: getDataFromLocalStorage(LOCAL_STORAGE.SESSION_ID, uniqueId()),
          },
          metadata: {
            button,
          },
          message,
        })
      )
    }
  }

  const handleFormSubmit = (message) => {
    dispatch(
      updateMessage({ id: message.id, updatedValues: { status: "pending" } })
    )
    const payload = {
      type: "message",
      info: {
        sender: MESSAGE_SENDER.USER,
        sessionId: getDataFromLocalStorage(LOCAL_STORAGE.SESSION_ID, uniqueId()),
      },
      metadata: {
        startNewSession,
        testBot: isTestBot,
      },
      message,
    }
    dispatch(sendMessage(payload))
  }

  const handleMessageSeen = useCallback((id, readStatus) => {
    const payload = {
      id,
      updatedValues: {
        readStatus,
      },
    }
    console.log("handleMessageSeen", payload)
  }, [])

  return (
    <div
      id="chatbotBody"
      ref={bodyRef}
      className={cn("relative flex-1 overflow-auto", notification ? 'pb-0' : 'pb-8')}
    >
      {(!tenantId || tenantId === "null") && <div className="p-2 text-center bg-red-100 text-error">
        <p><i class="ri-error-warning-line"></i> Invalid Bot URL (tenantId is missing!)</p>
      </div>}
      {loading ? (
        <div className="flex flex-col gap-2 p-2 my-1">
          <OriSkeleton className={'h-16 w-2/3 rounded-xl'} />
          <OriSkeleton className={'h-16 w-2/3 self-end rounded-xl'} />
        </div>
      ) : (
        messages.map((message, index) => {

          // Render System Message
          if ( message?.sender === MESSAGE_SENDER.SYSTEM && message?.payload?.text ) return (
              <ErrorBoundary key={message.id}>
                {renderSystemMessage(message)}
              </ErrorBoundary>
            )
          const showJumpingDots = !notification && typingInfo?.isTyping && index === messages.length - 1 && message.sender === MESSAGE_SENDER.CHATBOT
          const isNewUserMsg =
            message.sender === MESSAGE_SENDER.USER &&
            index === messages.length - 1

          return (
            <ErrorBoundary key={message.id}>
              {/* Render timestamps */}
              {!notification && index === 0 && message.timestamp && (
                <div className="flex justify-center mt-4">
                  <p className="py-1 px-3 bg-gray-100 text-xs rounded-full font-semibold text-gray-600">
                    {dayjs(message.timestamp).format("DD MMM YYYY")}
                  </p>
                </div>
              )}

              {/* Render message bubble */}
              {(_.get(message, 'payload.relayData.data[0].image', null) || 
              message.type === MESSAGE_TYPES.UPLOADED_DOCUMENT || 
              message.payload?.form?.length > 0 || 
              message.payload?.buttons?.length > 0 || 
              message?.payload?.text ||
              message?.failureDetails ||
              _.get(message, "payload.image", null)) && (
              <MessageWrapper
                id={message.id}
                trainingDisabled={!message.chatLogId}
                chatLogId={message.chatLogId}
                isLast={messages.length - 1 === index}
                isNewUserMsg={isNewUserMsg}
                sender={message.sender}
                senderInfo={message.senderInfo}
                timestamp={message.timestamp}
                status={message.status}
                readStatus={message.readStatus}
                flag={message.flag}
                markingFlag={message.markingFlag}
                onClickFlag={() => handleFlagClick(message)}
                onRetry={() => handleRetrySend(message)}
                onMessageSeen={handleMessageSeen}
                notification={notification}
                customerVote={message.customerVote}
                failureDetails={message.displayFailureDetails ? message.failureDetails : null}
                showEditAndTrain={isTraining && message.sender === MESSAGE_SENDER.CHATBOT && message.type === MESSAGE_TYPES.TEXT}
                editAndTrainPayload={{
                  query: messages?.[index - 1]?.payload?.text || "",
                  response: message?.payload?.text,
                  chatLogId: message.chatLogId,
                }}
                handleBrainClicked={handleBrainClicked}
              >
                {_.get(message, 'payload.relayData.data[0].image', null) && (
                  <div className="flex gap-2 items-center bg-black/20 p-2 rounded-xl mb-2">
                    <img
                      className="rounded-xl w-[80px] h-[80px] object-cover"
                      alt="productImage"
                      id={_.get(message, 'payload.relayData.data[0].image', '')}
                      src={_.get(message, 'payload.relayData.data[0].image', '')}
                    />
                    <div>
                      <p className="font-semibold break-words">
                        {_.get(message, 'payload.relayData.data[0].data.productDetails.title', '')}
                      </p>
                    </div>
                  </div>
                )}
                {message.type === MESSAGE_TYPES.UPLOADED_DOCUMENT ? (
                  <UploadedDocumentMessage payload={message.payload} />
                ) : message.sender === MESSAGE_SENDER.USER ? (
                  <p className="break-words block w-full">
                    {message?.payload?.text}
                  </p>
                ) : _.get(message, "payload.text[0]", "") === '<' ? (
                  <div className="break-words" dangerouslySetInnerHTML={{
                    __html: message?.payload?.text,
                  }} />
                ) : (
                  <Markdown components={{
                    a: (props) => {
                      const { href, children } = props;
                      return <a target={"_blank"} rel="noreferrer" href={href}>{children}</a>
                    }, 
                    pre: (props) => {
                      return <pre style={{
                        backgroundColor: "rgba(0, 0, 0, 0.9)",
                        color: "white",
                        width: "100%",
                        overflow: "auto",
                        padding: "12px",
                        borderRadius: "8px",
                        marginBlock: "8px",
                      }}>
                        {props.children}
                      </pre>
                    }
                  }}>
                    {message?.payload?.text}
                  </Markdown>
                )}
                {_.get(message, "payload.image", null) && (
                  <img
                    src={_.get(message, "payload.image", '')}
                    alt="attachment"
                    className={cn(
                      "block rounded-md min-w-[80px] my-2 w-full",
                      bubbleShape === BUBBLE_SHAPE.ROUNDED && "rounded-3xl",
                    )}/>
                )}
                {message?.payload?.form?.length > 0 && (
                  <Suspense>
                    <DynamicForm
                      form={message.payload.form}
                      userConsent={message.payload?.userConsent}
                      submitBtnText={message.payload.submitBtnText}
                      btnProps={message.payload.btnProps}
                      disabled={
                        message.payload.disabled || message.status === "pending"
                      }
                      onSubmit={(form) =>
                        handleFormSubmit({
                          ...message,
                          payload: {
                            ...message.payload,
                            ...form,
                          },
                        })
                      }
                    />
                  </Suspense>
                )}
                {message.payload?.buttons?.length > 0 &&
                  message.payload.buttons.map((button, i) => {
                    return (
                      <ErrorBoundary key={`${i}-${button.id || 1}`}>
                        <OriButton
                          size="small"
                          variant="outlined"
                          className="py-0.5 min-w-[50px] mr-2 mb-2 shadow-inner rounded-2xl"
                          disableElevation
                          {...button?.props}
                          onClick={() => handleButtonClick(button)}
                        >
                          {button?.text}
                        </OriButton>
                      </ErrorBoundary>
                    )
                  })}
                {showJumpingDots && (
                  <JumpingDots />
                )}
              </MessageWrapper >
              )}
              {message.payload?.carousel ? <div className="mt-4">
                <MessageCorouselData carouselData={message.payload?.carousel} />
              </div>
                : null}
            </ErrorBoundary>
          )
        })
      )}
      {!notification && typingInfo?.isTyping && messages?.[messages.length - 1]?.sender === MESSAGE_SENDER.USER && (
        <MessageWrapper sender={MESSAGE_SENDER.CHATBOT} isLast={true}>
          <JumpingDots />
        </MessageWrapper>
      )}
      <GlobalSnackBar />
    </div>
  )
}

Body.propTypes = {
  messages: PropTypes.array,
  notification: PropTypes.bool,
}

Body.defaultProps = {
  messages: [],
  notification: false,
}

export default Body

function renderSystemMessage(message) {
  return <div id={message.id} className="flex p-2 justify-center">
    <div
      className="bg-gray-100 text-gray-800 rounded-md text-xs text-center overflow-hidden">
      <p className="px-3 py-1">{message.payload.text}</p>
      {message.displayFailureDetails && message?.failureDetails && (
        <div className="p-2 bg-orange-50 flex items-center gap-2 shadow-inner">
          {message.displayFailureDetails && message?.failureDetails ? (
            <p className="text-orange-800">
              ({message?.failureDetails?.code}) {message?.failureDetails?.internalMessage}
            </p>
          ) :
            null
          }
          <OriIconButton variant='text' className="h-6 text-orange-800"
            onClick={() => copyToClipboard(`(${message?.failureDetails?.code}) ${message?.failureDetails?.internalMessage}`)}>
            <i class="ri-file-copy-line"></i>
          </OriIconButton>
        </div>
      )}
    </div>

    {/* TODO: Remove this code after review */}
    {/* <Typography
                    variant="caption"
                    component="p"
                    align="center"
                    color="text.secondary"
                    sx={{ bgcolor: "action.hover", px: 1.5, borderRadius: 1 }}
                  >
                    {message.payload.text}
                    {message.displayFailureDetails && message?.failureDetails ? <Alert variant="outlined" severity="warning" sx={{py:0, my:1}} action={
                      <Button color="inherit" size="small" onClick={()=>copyToClipboard(`(${message?.failureDetails?.code}) ${message?.failureDetails?.internalMessage}`)}>
                        COPY
                      </Button>
                    }><Typography
                      variant="caption">({message?.failureDetails?.code}) {message?.failureDetails?.internalMessage}</Typography></Alert>: null}
                  </Typography> */}
  </div>
}

