import React, { lazy, useState } from "react";
import { useSelector, useDispatch } from "react-redux"

import { resetConversation, toggleBotInterface } from "../../../data/store/botSlice";

import ErrorBoundary from "../ErrorBoundary"
import TrainAndTest from "../TrainAndTest"
import OriSkeleton from "../Custom/OriSkeleton"
import OriIconButton from "../Custom/OriIconButton"
import { useLocation, useNavigate } from "react-router-dom";
import { LOCAL_STORAGE, ROUTE_PATHS } from "../../../data/configs/constants";
import { removeDataFromLocalStorage } from "../../../data/configs/utils";
import OriDialog from "../Custom/OriDialog";

const Feedback = lazy(() => import("../Feedback"))
const EndSessionConfirmation = lazy(() => import("../EndSessionConfirmation"))

const Header = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const loading = useSelector((state) => state.themeDetails.loading)
  const botName = useSelector((state) => state.themeDetails.botName)
  // TODO: Remove this commented code after testing new ui
  // const headerHeight = useSelector((state) => state.themeDetails.headerHeight)
  const avatarUrl = useSelector((state) => state.themeDetails.avatarUrl)
  const fullScreenBot = useSelector((state) => state.themeDetails.fullScreenBot)
  const mobileView = useSelector((state) => state.themeDetails.mobileView)
  const connectionStatusText = useSelector(
    (state) => state.themeDetails.languageTemplate.connectionStatusText
  )
  const chatStatus = useSelector((state) => state.botDetails.chatStatus)
  const sessionId = useSelector((state) => state.botDetails.sessionId)
  const isSocketConnected = useSelector(
    (state) => state.botDetails.isSocketConnected
  )
  const showFeedback = useSelector((state) => state.themeDetails.showFeedback)
  const direction = useSelector((state) => state.themeDetails.direction)

  const [openDialog, setOpenDialog] = useState(false)
  const urlParams = new URLSearchParams(window.location.search)
  const isTraining = urlParams.get("training") || "false"

  const handleCloseChatbot = (e, reason) => {
    if (reason && reason === "backdropClick") return
    setOpenDialog(false)
    if (!fullScreenBot) {
      removeDataFromLocalStorage(LOCAL_STORAGE.CURRENT_SESSION_ID)
      dispatch(toggleBotInterface())
    }
  }

  const handleOpenDialog = () => {
    if (sessionId) setOpenDialog(true)
  }

  const handleBackPressed = () =>{ 
    removeDataFromLocalStorage(LOCAL_STORAGE.CURRENT_SESSION_ID)
    if(location.key === 'default') {
      navigate(ROUTE_PATHS.HOME)
    } else {
      navigate(-1)
    }
    dispatch(resetConversation())
  }

  if (loading) return (
    <OriSkeleton className="w-full h-16" />
  )

  return (
    <div>
      <div className="flex h-16">
        <div className="flex-grow bg-primary">
          <div className="w-full h-full bg-background rounded-tr-3xl flex items-center gap-2 p-2">
            <OriIconButton variant='text' className="text-2xl h-8" onClick={handleBackPressed}>
            <i class="ri-arrow-left-s-line"></i>
            </OriIconButton>
            <img
              src={chatStatus?.agentImgUrl || avatarUrl}
              alt={chatStatus?.agentName || botName}
              className="w-12 h-12 rounded-full object-cover" />
            <div>
              <p className="font-semibold line-clamp-1">
                {chatStatus?.agentName || botName}
              </p>
              {chatStatus?.currentStatus === "unassigned" ? (
                <p className="text-xs animate-pulse">
                  You are in the queue, please wait or leave your message.
                </p>
              ) :
                isSocketConnected
                  ? (
                    <p className="text-xs text-success">{connectionStatusText.online}</p>
                  )
                  : (
                    <p className="text-xs text-error animate-pulse">{connectionStatusText.offline}</p>
                  )
              }
            </div>
          </div>
        </div>
        <div>
          <div className="h-full bg-primary rounded-bl-3xl p-2 px-4 flex items-center justify-center">
            {isTraining === "true" && (
                <TrainAndTest />
            )}
            {fullScreenBot || !mobileView ? null : (
              <OriIconButton
                aria-label="minimizeChatbot"
                className="text-2xl h-8"
                onClick={handleCloseChatbot}>
                <i class="ri-fullscreen-exit-line"></i>
              </OriIconButton>
            )}
            <OriIconButton
              className="text-3xl h-8"
              onClick={sessionId ? handleOpenDialog : handleCloseChatbot}>
              <i class="ri-close-circle-fill"></i>
            </OriIconButton>
          </div>
        </div>
        <OriDialog open={openDialog} onClose={()=>{
          setOpenDialog(false)
        }}>
          <div className="relative p-6">
            {showFeedback && (
              <OriIconButton
                variant="outlined"
                className={`h-6 text-xl text-backgroundContrast absolute top-2 ${direction === "rtl" ? "left-2" : "right-2"}`}
                onClick={() => setOpenDialog(false)}>
                <i class="ri-close-line"></i>
              </OriIconButton>
            )}
            <ErrorBoundary onErrorCallback={handleCloseChatbot}>
              <React.Suspense fallback={null}>
                {showFeedback ? (
                  <Feedback onClose={handleCloseChatbot} />
                ) : (
                  <EndSessionConfirmation
                    onCancel={() => setOpenDialog(false)}
                    onClose={handleCloseChatbot}
                  />
                )}
              </React.Suspense>
            </ErrorBoundary>
          </div>
        </OriDialog>
      </div>
      {/* Below component is only for styling purposes */}
      <div className="h-4 w-full bg-primary">
        <div className="h-full bg-background w-full rounded-tr-3xl"/>
      </div>
    </div>

  )

  // TODO: Remove this code after testing new ui
  // return (
  //   <Box
  //     px={2}
  //     position="relative"
  //     display="flex"
  //     flexDirection="row"
  //     alignItems="center"
  //     backgroundColor="primary.main"
  //     color="primary.contrastText"
  //     minHeight={headerHeight}
  //     sx={
  //       chatStatus?.currentStatus === "assigned" ||
  //         chatStatus?.currentStatus === "unassigned"
  //         ? {
  //           backgroundImage: (theme) =>
  //             `-webkit-linear-gradient(55deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
  //         }
  //         : {}
  //     }
  //   >
  //     {loading ? (
  //       <Skeleton
  //         animation="wave"
  //         variant="circular"
  //         width={`calc(${headerHeight}/2)`}
  //         height={`calc(${headerHeight}/2)`}
  //         sx={{ mr: 1 }}
  //       />
  //     ) : (
  //       <>
  //         {/* {chatStatus?.currentStatus === "assigned" ||
  //           chatStatus?.currentStatus === "unassigned" ? (
  //           <Box
  //             className="Animated FadeIn"
  //             py={2.5}
  //             display="flex"
  //             flexDirection="column"
  //             justifyContent="center"
  //             alignItems="center"
  //             width="100%"
  //           >
  //             <Badge
  //               variant="dot"
  //               color={isSocketConnected ? "success" : "error"}
  //               overlap="circular"
  //               sx={{
  //                 "& .MuiBadge-badge": {
  //                   border: 1,
  //                   borderColor: "background.paper",
  //                 },
  //               }}
  //             >
  //               <Avatar
  //                 sx={{
  //                   width: 72,
  //                   height: 72,
  //                 }}
  //                 src={chatStatus.agentImgUrl || avatarUrl}
  //                 alt={chatStatus.agentName}
  //               />
  //             </Badge>
  //             <Typography
  //               variant="caption"
  //               fontWeight="fontWeightBold"
  //               mt={0.5}
  //               sx={(theme) => ({
  //                 opacity: theme.palette.action.disabledOpacity,
  //               })}
  //             >
  //               {isSocketConnected
  //                 ? connectionStatusText.online
  //                 : connectionStatusText.offline}
  //             </Typography>
  //             {chatStatus?.currentStatus === "assigned" && (
  //               <>
  //                 <Typography variant="h6">{chatStatus.agentName}</Typography>
  //                 <Typography variant="subtitle2">{botName}</Typography>
  //               </>
  //             )}
  //             {chatStatus?.currentStatus === "unassigned" && (
  //               <>
  //                 <Typography variant="h6">{botName}</Typography>
  //                 <Typography
  //                   variant="subtitle2"
  //                   sx={(theme) => ({
  //                     opacity: theme.palette.action.disabledOpacity,
  //                   })}
  //                 >
  //                   You are in the queue, please wait.
  //                 </Typography>
  //               </>
  //             )}
  //           </Box>
  //         ) : (
  //           <>
  //             <Badge
  //               variant="dot"
  //               color={isSocketConnected ? "success" : "error"}
  //               overlap="circular"
  //             >
  //               <Avatar
  //                 sx={{
  //                   width: `calc(${headerHeight}/2)`,
  //                   height: `calc(${headerHeight}/2)`,
  //                 }}
  //                 src={avatarUrl || IMG_LOGO}
  //               />
  //             </Badge>
  //             <Box
  //               mx={2}
  //               display="flex"
  //               flexDirection="column"
  //               justifyContent="center"
  //             >
  //               <Typography
  //                 variant="h6"
  //                 fontWeight="fontWeightBold"
  //                 sx={{ lineHeight: 1 }}
  //               >
  //                 {botName}
  //               </Typography>
  //               <Typography
  //                 variant="caption"
  //                 fontWeight="fontWeightBold"
  //                 sx={(theme) => ({
  //                   opacity: theme.palette.action.disabledOpacity,
  //                 })}
  //               >
  //                 {isSocketConnected
  //                   ? connectionStatusText.online
  //                   : connectionStatusText.offline}
  //               </Typography>
  //             </Box>
  //           </>
  //         )} */}
  //         {isTraining === "true" && (
  //           <Box flexGrow={1}
  //             marginRight={direction === "rtl" ? 0 : 4}
  //             marginLeft={direction === "rtl" ? 4 : 0}>
  //             <TrainAndTest />
  //           </Box>
  //         )}

  //         {/* <Box
  //           position="absolute"
  //           top={10}
  //           {...(direction === "rtl" ? { left: 10 } : { right: 10 })}
  //         >
  //           <IconButton
  //             aria-label="minimizeChatbot"
  //             sx={{
  //               color: "inherit",
  //               display: fullScreenBot || !mobileView ? "none" : "",
  //             }}
  //             onClick={handleCloseChatbot}
  //           >
  //             <ZoomInMapOutlinedIcon fontSize="inherit" />
  //           </IconButton>
  //           {sessionId && (
  //             <IconButton
  //               className="Animated FadeIn"
  //               aria-label="endChat"
  //               sx={{
  //                 color: "inherit",
  //               }}
  //               onClick={handleOpenDialog}
  //             >
  //               <CloseIcon fontSize="inherit" />
  //             </IconButton>
  //           )}
  //         </Box> */}
  //         {/* <Dialog
  //           aria-labelledby="customer-feedback"
  //           maxWidth="sm"
  //           slotProps={{
  //             backdrop: {
  //               invisible: true,
  //             },
  //           }}
  //           open={openDialog}
  //           onClose={handleCloseChatbot}
  //           fullWidth
  //           disableEscapeKeyDown
  //         >
  //           {showFeedback && (
  //             <IconButton
  //               sx={{
  //                 position: "absolute",
  //                 top: 4,
  //                 ...(direction === "rtl" ? { left: 4 } : { right: 4 }),
  //               }}
  //               onClick={() => setOpenDialog(false)}
  //             >
  //               <CloseIcon />
  //             </IconButton>
  //           )}
  //           <DialogContent sx={{ pb: 1 }}>
  //             <ErrorBoundary onErrorCallback={handleCloseChatbot}>
  //               <Suspense fallback={null}>
  //                 {showFeedback ? (
  //                   <Feedback onClose={handleCloseChatbot} />
  //                 ) : (
  //                   <EndSessionConfirmation
  //                     onCancel={() => setOpenDialog(false)}
  //                     onClose={handleCloseChatbot}
  //                   />
  //                 )}
  //               </Suspense>
  //             </ErrorBoundary>
  //           </DialogContent>
  //         </Dialog> */}
  //       </>
  //     )}
  //   </Box>
  // )
}




export default Header
