import PropTypes from "prop-types"
import { useState, useRef, useEffect, lazy, Suspense } from "react"
import { useDispatch, useSelector } from "react-redux"

import {
  addMessages,
  sendMessage,
  emitCustomEvent,
  updateBotState,
  sendMessageStream,
} from "../../../data/store/botSlice"

import {
  LOCAL_STORAGE,
  MESSAGE_SENDER,
  MESSAGE_TYPES,
  SOCKET_EVENTS,
} from "../../../data/configs/constants"
import { uniqueId, fileToBase64, cn } from "../../../data/configs/utils"

import QuickReply from "../QuickReply"
import ErrorBoundary from "../ErrorBoundary"
import ShowRelayData from "../ShowRelayData"
import OriTextArea from "../Custom/OriTextArea"
import OriSkeleton from "../Custom/OriSkeleton"
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react"
import OriButton from "../Custom/OriButton"
import OriLoader from "../Custom/OriLoader"

const EmojiPicker = lazy(() => import("../EmojiPicker"))

const Footer = () => {
  const dispatch = useDispatch()
  const [inputText, setInputText] = useState("")
  const [typing, setTyping] = useState(false)
  const [showEmojiPicker, setShowEmojiPicker] = useState(false)
  const loading = useSelector((state) => state.themeDetails.loading)
  const psid = useSelector((state) => state.botDetails.psid)
  const isTestBot = useSelector((state) => state.botDetails.isTestBot)
  const queryPlaceholder = useSelector(
    (state) => state.themeDetails.languageTemplate?.queryPlaceholder
  )
  const welcomeMessages = useSelector(
    (state) => state.botDetails.welcomeMessages
  )
  const messagesLength = useSelector(
    (state) => state.botDetails.messages?.length
  )
  const inputLock = useSelector(
    (state) => state.botDetails?.messages[messagesLength - 1]?.inputLock
  )
  const inputLockText = useSelector(
    (state) => state.botDetails?.messages[messagesLength - 1]?.inputLockText
  )
  const isQuickRepliesEmpty = useSelector(
    (state) => state.botDetails.quickReplies?.length === 0
  )
  const startNewSession = useSelector(
    (state) => state.botDetails.startNewSession
  )
  const chatStatus = useSelector((state) => state.botDetails.chatStatus)
  const hasChatEnded = useSelector((state) => state.botDetails.hasChatEnded)
  const mobileView = useSelector((state) => state.themeDetails.mobileView)
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const didMount = useRef(false)
  const typingTimerRef = useRef(null)
  const fileUploaderInputRef = useRef(null)

  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true
    } else {
      dispatch(
        emitCustomEvent({
          event: SOCKET_EVENTS.TYPING_STATUS,
          data: {
            isTyping: typing,
            clientPsid: psid,
          },
        })
      )
    }
  }, [dispatch, psid, typing])

  useEffect(() => {
    let timer = null
    if (chatStatus?.currentStatus === "assigned")
      timer = setTimeout(() => {
        dispatch(
          emitCustomEvent({
            event: SOCKET_EVENTS.UNSENT_MESSAGE,
            data: {
              text: inputText,
              clientPsid: psid,
            },
          })
        )
      }, 700)

    return () => {
      if (timer) clearTimeout(timer)
    }
  }, [chatStatus?.currentStatus, dispatch, inputText, psid])

  const handleInputChange = (e) => {
    setInputText(e.target.value)
    setShowEmojiPicker(false)
  }

  const handleInputKeyDown = (e) => {
    clearTimeout(typingTimerRef.current)
    if (e.keyCode === 13 && !e.shiftKey && inputText?.trim().length > 0) {
      handleMessageSend(e)
      setTyping(false)
    } else {
      if (!typing) setTyping(true)
      typingTimerRef.current = setTimeout(() => {
        setTyping(false)
      }, 2000)
    }
  }

  const handleMessageSend = (e, reply) => {
    setShowEmojiPicker(false)
    let text = ""
    if (reply) {
      text = reply.trim()
    } else {
      e.preventDefault()
      text = inputText.trim()
    }

    if (text) {
      onSend({ text }, MESSAGE_TYPES.TEXT)
    }
  }

  const onSend = (data, messageType) => {
    const message = {
      id: uniqueId(),
      type: messageType,
      sender: MESSAGE_SENDER.USER,
      payload: {
        ...data,
      },
      status: "pending",
      timestamp: new Date().getTime(),
    }
    dispatch(addMessages({ messages: [message] }))
    setInputText("")
    const payload = {
      type: "message",
      info: {
        // platformName: PLATFORM,
        sender: MESSAGE_SENDER.USER,
        sessionId: sessionStorage.getItem(LOCAL_STORAGE.SESSION_ID), 
        // tenantId,
        // psid,
      },
      metadata: {
        startNewSession,
        testBot: isTestBot,
      },
      message,
    }
    if (welcomeMessages.length === messagesLength)
      payload.metadata.welcomeMessages = welcomeMessages
    if (process.env.REACT_APP_STREAM_RESPONSE === "true") dispatch(sendMessageStream(payload))
    else dispatch(sendMessage(payload))
  }

  const handleQuickReply = (reply) => handleMessageSend(null, reply)

  const handleOpenEmojiPicker = () =>
    setShowEmojiPicker((prevState) => !prevState)

  const handleEmojiSelect = (emoji) => {
    setInputText((prevInputText) => prevInputText + emoji.native)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const clearExistingInput = (event) => {
    event.target.value = null
  }

  const handleFileUploadClick = (type) => {
    if (fileUploaderInputRef.current) {
      if (type === "image") {
        fileUploaderInputRef.current.accept = "image/*"
      } else if (type === "pdf") {
        fileUploaderInputRef.current.accept = "application/pdf"
      }
      fileUploaderInputRef.current.click()
    }
    handleMenuClose()
  }

  const handleFileUpload = (e) => {
    if (
      fileUploaderInputRef.current.accept === "image/*" &&
      e.target?.files[0]?.type?.includes("image")
    ) {
      if (e.target.files[0]?.size < 1024 * 1024 * 2) {
        const file = e.target.files[0]
        fileToBase64(file).then((fileUrl) => {
          const payload = {
            fileName: file.name,
            fileSize: file.size,
            fileType: file.type,
            fileUrl,
          }
          onSend(payload, MESSAGE_TYPES.UPLOADED_DOCUMENT)
        })
      } else
        dispatch(
          updateBotState({
            snackbarProps: {
              open: true,
              message: "Image must be smaller than 2MB",
            },
          })
        )
    } else if (
      fileUploaderInputRef.current.accept === "application/pdf" &&
      e.target?.files[0]?.type?.includes("application/pdf")
    ) {
      if (e.target.files[0]?.size < 1024 * 1024 * 2) {
        const file = e.target.files[0]
        const reader = new FileReader()
        reader.readAsBinaryString(file)
        reader.onloadend = () => {
          const pages =
            reader.result?.match(/\/Type[\s]*\/Page[^s]/g)?.length || 0
          fileToBase64(file).then((fileUrl) => {
            const payload = {
              fileName: file.name,
              fileSize: file.size,
              fileType: file.type,
              fileUrl,
              pages,
            }
            onSend(payload, MESSAGE_TYPES.UPLOADED_DOCUMENT)
          })
        }
      } else
        dispatch(
          updateBotState({
            snackbarProps: {
              open: true,
              message: "Pdf must be smaller than 2MB",
            },
          })
        )
    } else
      dispatch(
        updateBotState({
          snackbarProps: {
            open: true,
            message: "Selected file is not valid",
          },
        })
      )
  }

  const handleAskAQuestionClick = () => {
    dispatch(updateBotState({
      hasChatEnded: false,
      messages: [
        ...welcomeMessages
      ],
    }))
  }

  if (hasChatEnded) return <div className="p-4 border-t-[1px] flex flex-col gap-2 items-center">
    <p className="text-center font-medium text-gray-500">Your conversation has ended</p>
    <OriButton onClick={handleAskAQuestionClick}>
      Ask a question
    </OriButton>
  </div>

  return (
    <>
      <input
        type="file"
        ref={fileUploaderInputRef}
        accept="*"
        onClick={clearExistingInput}
        onChange={handleFileUpload}
        hidden
      />
      {!isQuickRepliesEmpty && (
        <QuickReply inputLock={inputLock} onClick={handleQuickReply} />
      )}
      <div className="p-2 w-full">
        <ShowRelayData />
        <div className="flex items-end gap-2 w-full">
          {loading ? (
            <OriSkeleton className="h-12 w-full rounded-3xl" />
          ) : (
            <>
              <div className="relative flex items-center flex-grow border shadow-inner rounded-3xl min-h-12 p-2">
                {/* Emoji picker */}
                {showEmojiPicker && (
                  <ErrorBoundary>
                    <Suspense fallback={<OriLoader className={'w-min aspect-square absolute left-0 bottom-0 bg-background rounded-full'} />}>
                      <div className="absolute left-0 right-0 bottom-16 z-10">
                        <EmojiPicker
                          theme="light"
                          onEmojiSelect={handleEmojiSelect}
                        />
                      </div>
                    </Suspense>
                  </ErrorBoundary>
                )}
                {/* Emoji picker trigger */}
                {!mobileView &&
                  (chatStatus?.currentStatus === "assigned" ||
                    chatStatus?.currentStatus === "unassigned") && (
                    <button
                      aria-label="emoji-trigger-icon"
                      className={cn("rounded-full text-xl", showEmojiPicker && "text-yellow-500")}
                      onClick={handleOpenEmojiPicker}>
                      <i class="ri-emoji-sticker-line"></i>
                    </button>
                  )
                }
                <OriTextArea
                  id="inputComposer"
                  type="text"
                  className="w-full px-2 outline-none resize-none text-base border-none rounded-3xl"
                  placeholder={inputLockText ? inputLockText : queryPlaceholder}
                  value={inputText}
                  disabled={inputLock || false}
                  maxRows={3}
                  onChange={handleInputChange}
                  onKeyDown={handleInputKeyDown}
                  autofocus
                />
              </div>
              {inputText?.trim() ? (
                <button
                  aria-label="send-icon"
                  className="h-12 rounded-full text-lg bg-primary aspect-square text-primaryContrast active:scale-90 duration-500"
                  onClick={handleMessageSend}>
                  <i class="ri-send-plane-line"></i>
                </button>
              ) : (
                (chatStatus?.currentStatus === "assigned" ||
                  chatStatus?.currentStatus === "unassigned") && (
                  <Menu>
                    <MenuButton>
                      <button
                        aria-label="file-upload-icon"
                        aria-controls={open ? "file-upload-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        className="h-12 rounded-full bg-primary text-lg aspect-square text-primaryContrast duration-500 active:scale-90"
                        onClick={handleMenuOpen}>
                        <i class="ri-add-large-line"></i>
                      </button>
                    </MenuButton>
                    <MenuItems
                      transition
                      anchor="top end"
                      className={'bg-background p-2 origin-top-left rounded-xl border [--anchor-gap:8px] transition duration-100 ease-out focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0'}>
                      <MenuItem className="block data-[focus]:bg-gray-100" onClick={() => handleFileUploadClick("image")}>
                        <button className="flex w-full p-2 rounded-md gap-2">
                          <i class="ri-image-line"></i>
                          <p>Image</p>
                        </button>
                      </MenuItem>
                      <MenuItem className="block data-[focus]:bg-gray-100" onClick={() => handleFileUploadClick("pdf")}>
                        <button className="flex w-full p-2 rounded-md gap-2">
                          <i class="ri-file-line"></i>
                          <p>Document</p>
                        </button>
                      </MenuItem>
                    </MenuItems>
                  </Menu>
                )
              )}
              {/* <Menu
              id="live-chat-file-upload-menu"
              anchorEl={anchorEl}
              open={open}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={() => handleFileUploadClick("image")}>
                <ImageIcon sx={{ mx: 1, color: "text.disabled" }} /> Image
              </MenuItem>
              <MenuItem onClick={() => handleFileUploadClick("pdf")}>
                <DescriptionIcon sx={{ mx: 1, color: "text.disabled" }} /> Document
              </MenuItem>
            </Menu> */}
            </>
          )}
        </div>
      </div>
      {/* Watermark */}
      {/* {watermark &&
        (branding?.active || branding?.brandName) && (
          <p className="text-xs font-light text-center p-2 pt-0">Powered by {" "}
            <span className="font-bold">
              {branding.url ? (
                <a
                  href={branding.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  {branding.brandName}
                </a>
              ) : (
                branding.brandName
              )}
              {branding.showVersion &&
                process.env.REACT_APP_VERSION &&
                `  ${process.env.REACT_APP_VERSION}`}
            </span>
          </p>
        )
      } */}
    </>
  )
}

Footer.propTypes = {
  watermark: PropTypes.bool,
}

Footer.defaultProps = {
  watermark: true,
}

export default Footer
