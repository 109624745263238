import { Dialog } from '@headlessui/react'
import React from 'react'
import { cn } from '../../../../data/configs/utils'
import { useSelector } from 'react-redux'

const OriDialog = ({
    open,
    onClose,
    children,
}) => {
    const mobileView = useSelector((state) => state.themeDetails.mobileView)
    const fullScreenBot = useSelector((state) => state.themeDetails.fullScreenBot)

    return (
        <Dialog
            open={open}
            onClose={onClose}
            className="relative z-50"
        >
            <div className={cn("fixed inset-0 bg-black/20",
                mobileView || fullScreenBot ? 'top-0 left-0 right-0 bottom-0' : 'top-[8px] left-[8px] right-[8px] rounded-3xl bottom-[--trigger-size] mb-4 shadow-xl border',
            )} aria-hidden="true" />
            <div className="fixed inset-0 flex items-center justify-center p-4">
                <Dialog.Panel className="w-full max-w-sm bg-background rounded-2xl">
                    {children}
                </Dialog.Panel>
            </div>
        </Dialog>
    )
}

export default OriDialog